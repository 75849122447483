.WhyWorkWithUsPage{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.WhyWorkWithUsSection{
    margin-top: 0px;
    margin-bottom: 0px;
    /* max-width: 1100px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #343639;
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 20px);
}

.WhyWorkWithUsSection.Bottom {
  margin-top: 50px;
  margin-bottom: 50px;
}

.WhyWorkWithUsSection.GreySection {
  margin-top: 30px;
  margin-bottom: 30px;
  

}

/* .BlueSection .title { */

/* } */

.WhyWorkWithUsBannerTextContainer{
    max-width: 950px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 2;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 50px;

}

.WhyWorkWithUsBanner{
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 17px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #343639;
    margin-bottom: 20px;
    background: #ffffff;
}

.WhyWorkWithUsBannerCoaching .WhyWorkWithUsBannerTextContainer{
  min-height: 220px;
}

.WhyWorkWithUsBannerBG{
    background-image: url("https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/why_work_with_us.jpg");
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 80% 0%;
    opacity: 0.3;
  }

  .Recruitment .WhyWorkWithUsBannerBG{
    background-image: url("https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/DubaiBannerLarge.jpg");
    background-color: #073631;
    background-position: 50% 30%;
    /* filter:  grayscale(0.8) brightness(0.85); */
    background-repeat: repeat;
    background-size: cover;
    height: 30vw;
    opacity: 1;
  }

  .Recruitment .WhyWorkWithUsBannerRecruitmentBG{
    background-image: url("https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/archjobs_recruitment_banner_client.jpg");
    background-color: #073631;
    background-position: 20% 20%;
    filter:  grayscale(0.3);
    background-repeat: repeat;
    background-size: cover;
    height: 30vw;
  }

  .WhyWorkWithUsDarkSection {
    background: radial-gradient(107.66% 510.14% at 0% 2.24%, #073631 20.49%, #04423A 48.19%, #044E45 81.94%);
    padding-top: 20px;
    padding-bottom: 20px;
    color: #F1F4F4; 

    font-family: Inter;
  }

  .WhyWorkWithUsDarkSection .PostJobForm {
    background: rgba(0, 0, 0, 0.1);
    color: #FAF9F8;
    border: 1px solid  #FDD85E;
  }

  .WhyWorkWithUsDarkSection .PostJobForm .MuiFormControl-root.MuiTextField-root  {
    background-color: #073631;
    color: #403E33;
  }

  .WhyWorkWithUsDarkSection .PostJobInput .MuiOutlinedInput-input.MuiInputBase-input {
    background-color: #FAF9F8;
    color: #403E33;
    border-radius: 4px;
  }

  .WhyWorkWithUsDarkSection .PostJobFormHeader {
    color: #F1F4F4; 
  }

  .WhyWorkWithUsDarkSection .PostJobFormInputTitle {
    color: #F1F4F4; 
  }

  .WhyWorkWithUsDarkSection .JobSubmissionsubtitle {
    color: #F1F4F4; 
    font-weight: 300;
  }

  .WhyWorkWithUsDarkSection .JobDescriptionInput {
    background: #FAF9F8
  }

  .ql-editor{
    min-height: 200px;
  }

  .WhyWorkWithUsDarkSection .ql-toolbar.ql-snow {
    border-color: #FDD85E;
    border-radius: 4px 4px 0px 0px
  }

  .Recruitment .WhyWorkWithUsDarkSection .Button {
    background-color: #FDD85E;
    color: #403E33;
    padding: 12px 22px;
    font-size: 24px;
  }

  .WhyWorkWithUsDarkSection .ResultsGoBack {
    color: #a3a9a9;
  }

  .WhyWorkWithUsDarkSection .PostJobForm  .JobDescriptionInputContainer {
    color: #403E33;
  }

  .Recruitment .ql-snow .ql-fill, .Recruitment .ql-snow .ql-stroke.ql-fill {
    fill: #F1F4F4;
  }

  .Recruitment .ql-snow .ql-stroke {
    stroke: #F1F4F4;
  }

  .ContactLinks {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-size: 20px;
    align-items: flex-start;
    margin-left: 4px;

  }

  .RecruitmentPage .ContactLinks {
    margin-left: 60px;
  }

  .WhyWorkWithUsDarkSection .ContactLink {
    color: #FDD85E;
  }

  .ContactLink{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 6px;
    flex-shrink: 1;
    font-weight: 400;
  }

  .ContactLink {
    color: #1F2023;
  }

  .ContactLink img {
    width: 24px;
  }

  .Recruitment .Button.FileUploadButton {
    font-size: 16px;
    padding: 10px 20px;
    background-color: #1f615b;
    color: #F1F4F4;
  }
  .WhyWorkWithUsDarkSection .PostJobFormText {
    color: #a3a9a9;
  }

  .Recruitment .WhyWorkWithUsBannerJobseekerBG{
    background-image: url("https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/archjobs_recruitment_jobseeker_banner.jpg");
    background-color: #073631;
    background-position: 20% 60%;
    /* filter:  grayscale(0.9) brightness(0.7); */
    background-repeat: repeat;
    background-size: cover;
    height: 30vw;
  }

  .Recruitment .WhyWorkWithUsBannerTextContainer {
    padding-bottom: 5vw;
    max-width: calc(100% - 20px);
  }

  .Recruitment .WhyWorkWithUsSectionTitle {
    font-family: Inter;
    font-size: 44px;
    font-weight: 700;
    color: #F6EFE8;
    background: radial-gradient(107.66% 510.14% at 0% 2.24%, #073631 20.49%, #04423A 48.19%, #044E45 81.94%);
    padding: 20px 40px;
    border-radius: 20px;
  }

  .Recruitment .WhyWorkWithUsSectionSubtitle {
    font-family: Inter;
    font-size: 28px;
    font-weight: 500;
    color: #F6EFE8;
    line-height: 1.3;
    margin-top: -5px;
    width: auto;
    max-width: 100%;
    background: radial-gradient(107.66% 510.14% at 0% 2.24%, #073631 20.49%, #04423A 48.19%, #044E45 81.94%);
    padding: 16px 40px;
    border-radius: 20px;
  }

  .Recruitment .WhyWorkWithUsBanner {
    background-color: #073631;
    height: 30vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  
  .ArchjobsHome .WhyWorkWithUsBanner {
    background-color: #073631;
    height: 30vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }


  .WhyWorkWithUsBannerBG.CoachingBG{
    background-image: url("https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/findarecruiterheader.jpg"); 
  }

  .WhyWorkWithUsBannerBG.HomepageBannerBG{
    background-image: url("https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/archjobs_homepage_banner.jpg"); 
    background-color: #fff;
    background-position: 20% 50%;
    filter:  grayscale(0.1) brightness(0.9);
    background-repeat: repeat;
    background-size: cover;
    opacity: 0.5;
    height: 30vw;
  }

  .ArchjobsHome .WhyWorkWithUsBannerTextContainer {
    flex: 1 1 auto;
    padding-bottom: 5vw;
    width: 1200px;
    max-width: calc(100% - 20px);
  }

  .HomePage.ArchjobsHome {
    background: #FAF9F8;
  }

  .ArchjobsHome .SectionSeparator {
    color: #343639;
  }

  .ArchjobsHome .HomePageSectionSeparator {
    border-color: #f6efe8;
  }

  .ArchjobsHome .WhyWorkWithUsSectionTitle {
    color: #343639;
    max-width: 100%;
  }

  .ArchjobsHome .CircularImage {
    border-color: #f6efe8;
  }


  .ArchjobsHome .WhyWorkWithUsSectionSubtitle {
    color: #343639;
    max-width: 100%;
    margin-top: 30px;
    font-size: 32px;
  }

  .ArchjobsHome .WhyWorkWithUsBanner {
    background-color: #fff;
  }

  /* .ArchjobsHome .SectionSeparator {

  } */

  .ArchjobsHome .WhyWorkWithUsSectionTitle {
    /* font-family: Inter; */
    font-size: 56px;
    line-height: 56px;
    font-weight: 700;
  }

  .WhyWorkWithUsBannerFARBG{
    background-image: url("https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/findarecruiterheader.jpg");
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 80% 0%;
    opacity: 0.4;
  }

  .WhyWorkWithUsHeaderTitle{
    font-weight: 600;
    font-size: 40px;
    line-height: 53px;
    text-align: center;
    max-width: 700px;
    padding-bottom: 5px;
  }

  .WhyWorkWithUsHeaderText{
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    max-width: 800px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .WhyWorkWithUsHeaderButton{
    margin-top: 20px;
  }

  .PostJobButton{
    padding: 8px 16px;
    font-size: 16px;
    background-color: #073631;
  }

  .WhyWorkWithUsSectionCall {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .WhyWorkWithUsSectionTestimonials {
    background: #f5f5f5;
    padding: 40px 0;
    width: 100%;
  }

  .TestimonialSliderContainer {
    max-width: 700px;
    margin: 0 auto;
    padding: 0 50px;
    box-sizing: border-box;
  }

  .WhyWorkWithUsTestimonial {
    padding: 20px;
    margin: 10px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .WhyWorkWithUsTestimonialText {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  /* Slider Navigation */
  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    z-index: 1;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-dots {
    bottom: -30px;
  }

  @media (max-width: 768px) {
    .TestimonialSliderContainer {
      padding: 0 20px;
      width: 100%;
      max-width: 100%;
    }

    .WhyWorkWithUsTestimonial {
      padding: 15px;
      margin: 5px;
    }

    .WhyWorkWithUsTestimonialText {
      font-size: 14px;
      line-height: 1.4;
    }

    .WhyWorkWithUsSectionTitle {
      font-size: 24px;
      padding: 0 20px;
      margin-bottom: 10px;
    }

    .WhyWorkWithUsSectionSubtitle {
      font-size: 16px;
      padding: 0 20px;
      margin-bottom: 20px;
    }

    .slick-dots {
      bottom: -25px;
    }

    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }

  @media (max-width: 480px) {
    .WhyWorkWithUsSectionTestimonials {
      padding: 20px 0;
    }

    .TestimonialSliderContainer {
      padding: 0 10px;
    }

    .WhyWorkWithUsTestimonial {
      padding: 12px;
      margin: 3px;
    }

    .WhyWorkWithUsTestimonialText {
      font-size: 13px;
      line-height: 1.3;
    }

    .WhyWorkWithUsSectionTitle {
      font-size: 22px;
    }

    .WhyWorkWithUsSectionSubtitle {
      font-size: 14px;
    }
  }

  .GreySection {
    background-color: #F2F1EF;
    padding-top: 20px;
  }

  .StartNowButtonContainer.StartNowButtonPaddedBottom{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .TaylrHomePageBenefitsSection{
    max-width: 950px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .TaylrHomePageBenefits {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
    flex-wrap: wrap;
    column-gap: 10px;
  }
  
  .TaylrHomePageBenefit{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: calc(30% - 40px);
    /* min-width: 200px; */
    background: #F6F9FF;
    padding: 20px;
    border-radius: 10px;
  }
  
  .TaylrHomePageFeatureTitle {
    font-weight: 500;
    font-size: 20px;
    color: #343639;
    margin-bottom: 5px;
  }
  
  .TaylrHomePageBenefitText {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 120.69%;
      /* or 21px */
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      
      color: #0B152C;
  }

  .TaylrHomePageFeatures {
    padding-top: 20px;
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    max-width: 900px;
  }

  .WhyWorkWithUsSectionTitle{
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    max-width: 700px;
    margin-bottom: 0px;
    align-items: center;
    margin-top: 20px;
    color: #343639;
  }

  .WhyWorkWithUsSectionTitleBold {
    color: #343639;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .WhyWorkWithUsBannerTextContainer .WhyWorkWithUsSectionTitleBold{
    font-size: 30px;
    color: #343639;
    margin-bottom: 10px;
  }

  .WhyWorkWithUsSectionSubtitle{
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    max-width: 900px;
    width: calc(100% - 20px);
  }

  .WhyWorkWithUsSectionReasons{
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    max-width: 700px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    text-align: center;
  }

  .WhyWorkWithUsSection .MeetTheCoachHub {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    max-width: 700px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    text-align: center;
  }

  .WhyWorkWithUsSectionRetainer{
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    max-width: 700px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    text-align: center;
  }

  .WhyWorkWithUsReasons{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    align-items: stretch;
  }

  .WhyWorkWithUsSectionRolesTitle{
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    max-width: 700px;
    margin-bottom: 10px; 
  }

  .WhyWorkWithUsSectionRoles{
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    max-width: 700px;
    text-align: center;
    margin-bottom: 10px;
  }

  .WhyWorkWithUsThird{
    background: #1D3863;
    border: 1px solid #1F2C42;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: #ECECEC;
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1 1 auto;
  }

    .WWWUThirdTitle{
        color: #fff;
        font-weight: 500;
        font-size: 28px;
        line-height: 33px;
        margin-top: 10px;
        margin-bottom: 20px;
        text-align: center;
    }

  .WWWUThirdTextContainer{
    display: flex;
    flex-direction: column;

  }

  .WWWUThirdTextLine{
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }

  .CircleCheck{
    min-width: 20px;
    margin-top: 2px;
  }

  .BottomPadding{
    height: 30px;
  }

  .WWWUTable {
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    width: 100%;
    max-width: 800px;
    border: 1px #E3E0DC;
    overflow: auto;
  }

  .WWWUTable table{
    background: #fff;
    color: #484E57;
    width: 100%;

    border-collapse: collapse;
    border:none;
    
 

  }

  .ColumnHeader{
    min-height: 50px;
    max-width: 300px;
    text-align: end;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 5px;
  }

  .WWWUTable tr:nth-child(odd) {background: #FAF9F8}

  .WWWUTable table td{
    border-left: 1px solid #E3E0DC;
    min-height: 50px;
  }

  .WWWUTable table th{
    border-left: 1px solid #E3E0DC;
  }

  .WWWUTable table td:first-child, th:first-child {
    border-left: none !important;
   }

   .WWWUTable table td:last-child, th:last-child{
    border-right: none;
    }


  .WWWUTable thead tr{
    background: #fff !important;
  }



  .TableRowItem{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
  }

  .TableRowHeader {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;

    /* darker grey */

    color: #484E57;
  }

  .Check{
    font-size: 24px;
    color: #77D260;
    min-width: 24px;
    margin-right: 2px;
    margin-top: -3px;
  }

  .Cross{
    font-size: 24px;
    color: #D67676;
  }

  .WhyWorkPricing{
    padding: 4px;
    min-width: 120px;
  }

  .WhyWorkFreeTrial{
    font-weight: 500;
    font-size: 24px;
    text-align: center;
  }

  .WhyWorkPricingRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2px;
  }

  .Cancelled {
    text-decoration: line-through;
  }

  .WhyWorkPricingVAT{
    text-align: end;
    font-size: 12px;
    margin-right: 4px;
  }

  .PostJobPage{
    padding: 10px;
  }

  .PostJobPackageContainer{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    column-gap: 20px;
    flex: 1 1 auto;
    flex-wrap: wrap;
    row-gap: 10px;
    
  }

  .PostJobPackage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    /* identical to box height */
    text-align: center;
    /* Dark Blue */
    color: #182037;
    min-width: 220px;
    cursor: pointer;
    border: 2px solid #fff;
    flex: 1 1 auto;
    width: 30%;
    max-width: 300px;
  }

  .StartNowButtonPaddedBottom{
    padding-bottom: 30px;
  }

  .PostJobPackage:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .PostJobPackageSelected{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 2px solid #2D63BA;
  }

  .PackagePrice {
    font-weight: 600;
    font-size: 33px;
  }

  .WhyWorkWithUsSection .PackageTitle {
    font-size: 33px;
    line-height: 41px;
  }

  .WhyWorkWithUsSection .PackagePrice {
    font-weight: 500;
    font-size: 40px;
  }

  .WhyWorkWithUsSection .PostJobPackage {
    padding-top: 40px;
    cursor: auto;
    padding-bottom: 40px;
    border: 2px solid #fff;
    min-width: 150px;
  }

  .WhyWorkWithUsSection #standard {
    border: 2px solid #0e4742;
  }

  
  .WhyWorkWithUsSection .MeetTheCoach {
      font-size: 18px;
      line-height: 25px;
      padding-left: 5px;
      padding-right: 5px;
      width: calc(100% -10px);
      max-width: 800px;
  }

  .WhyWorkWithUsCoachingPackage {
    margin-top: 20px;
    margin-bottom: 20px;
    width: calc(100% - 50px);
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
    background: rgba(0, 0, 0, 0.02);
  }

  .CoachingPackageTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 5px;
    align-items: center;
  }

  .CoachingPackageSubtitle{
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    max-width: 700px;
    margin-bottom: 10px;
    text-align: center;
  }

  .WhyWorkWithUsCoachingPackage{
    border-radius: 6px;
    border: 1px solid #c9c2c2;  
    background: #FAF9F8;
  }

  #ChoosePackageSection .WhyWorkWithUsSectionTitleBold {
    font-size: 34px;
  }

  #ChoosePackageSection .WhyWorkWithUsSectionSubtitle {
    margin-bottom: 30px;
  }

  #ChoosePackageSection .PackageTitle{
    font-size: 28px;
  }

  #ChoosePackageSection .PackagePrice {
    font-size: 36px;
  }

  #ChoosePackageSection .PricingPackages{
    column-gap: 10px;
  }

  .WhyWorkWithUsTestimonialColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1100px;
    column-gap: 10px;
    row-gap: 10px;
    width: calc(100% - 20px);
  }

  .WhyWorkWithUsTestimonialColumn{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    row-gap: 10px;
  }

  .PackageDescription{
    min-height: 225px;
  }

  #standard .PackageDescription{
    min-height: 245px;
  }

  .PackagePerAd {
    text-align: center;
    font-size: 17px;
    color: #4B5763;
    font-weight: 400;
    padding-bottom: 20px;
    border-bottom: 1px solid #D9D9D9;
    flex: 1 1 auto;
    text-align: center;
    width: 100%;
  }

  .PricingPackages{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    column-gap: 20px;
    row-gap: 10px;
    /* flex-wrap: wrap; */
  }

  .CoachingPricingPackages .PostJobPackage {
    padding: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .PackageDescription{
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    padding-top: 20px;
    row-gap: 8px;
  }

  .PackageDescriptionText{
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: #484E57;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 300px;
    text-align: left;
  }

  .PackagePriceNote {
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: -25px;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .Discount {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 4px; 
    padding: 2px 6px;
    border-radius: 8px;
    background-color: #1D3863;
    color: #fff;
  }
  .switch-wrapper {
    position: relative;
    display: inline-flex;
    padding: 4px;
    border: 1px solid lightgrey;
    margin-bottom: 40px;
    border-radius: 30px;
    background: rgba(0,0,0,0.05);
  }
  .switch-wrapper [type="radio"] {
    position: absolute;
    left: -9999px;
  }
  .switch-wrapper [type="radio"]:checked#monthly ~ label[for="monthly"],
  .switch-wrapper [type="radio"]:checked#yearly ~ label[for="yearly"] {
    color: var(--white);
  }
  .switch-wrapper [type="radio"]:checked#monthly ~ label[for="monthly"]:hover,
  .switch-wrapper [type="radio"]:checked#yearly ~ label[for="yearly"]:hover {
    background: transparent;
  }
  .switch-wrapper
    [type="radio"]:checked#monthly
    + label[for="yearly"]
    ~ .highlighter {
    transform: none;
  }
  .switch-wrapper
    [type="radio"]:checked#yearly
    + label[for="monthly"]
    ~ .highlighter {
    transform: translateX(130px);
  }
  .switch-wrapper label {
    font-size: 16px;
    z-index: 1;
    min-width: 110px;
    line-height: 32px;
    cursor: pointer;
    border-radius: 30px;
    column-gap: 4px;
    transition: color 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
  }
  /* .switch-wrapper label:hover {
    background: #FAF9F8;
  } */
  .switch-wrapper .highlighter {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    border-radius: 30px;
    background: #fff;
    transition: transform 0.25s ease-in-out;
  }
  .switch-wrapper .toggleLabelYearly {
    width: 150px;
  }
  .switch-wrapper .highlighterYearly {
    width: 170px;
  }

  .switch-wrapper .highlighterMonthly {
    width: 130px;
  }

  .PackagePerAdSubtext {
    font-size: 12px;
    color: #4B5763;
    font-weight: 300;
    height: 16px;
    margin-bottom: -10px;
  }

  
  .WhyWorkWithUsTestimonials {
    padding-top: 10px;
    padding-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;
    column-gap: 20px;
    row-gap: 10px;
  }

  
  .WhyWorkWithUsTestimonial {
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #D8DEEB;
    box-shadow: 0px 2px 4px rgba(246, 249, 255, 0.25);
    border-radius: 8px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .WhyWorkWithUsTestimonialText {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #293247;
  }

  .TestimonialImage{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .ArchJobsTestimonialProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    
  }
  
  .ArchJobsTestimonialProfileText {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    color: #3F3F3F;
  }
  
  .TestimonialName{
    font-size: 17px;
    font-weight: 600;
  }
  
  .TestimonialCompany{
    font-weight: 500;
    text-decoration: none;
    color: #3F3F3F;
  }

  @media (max-width: 1100px) {
    .Recruitment .WhyWorkWithUsSectionTitle {
      font-size: 32px;
      /* margin-top: 0px; */
      padding: 16px 28px;
    }

    .Recruitment .WhyWorkWithUsSectionSubtitle {
      font-size: 22px;
      /* margin-top: 10px; */
      padding: 12px 24px;
    }

    .ArchjobsHome .WhyWorkWithUsSectionTitle {
      font-size: 32px;
      line-height: 1.2;
    }

    .ArchjobsHome .WhyWorkWithUsSectionSubtitle {
      font-size: 24px;
      margin-top: 15px;
    }

    .ArchjobsHome .HomePageSectionTitle {
      font-size: 28px;
    }

    .ArchjobsHome .HomePageSectionSubtitle {
      font-size: 22px;
    }

  }


  @media (max-width: 900px) {
    .PricingPackages {
      flex-wrap: wrap;
    }

    .Recruitment .WhyWorkWithUsBannerTextContainer {
      padding-bottom: 0px;
    }

    .HomePageSplitSection  {
      flex-direction: column;
      row-gap: 10px;
      align-items: center;
    }

    .HomePageSplitSection.SplitRight  {
      flex-direction: column-reverse;
    }
    .HomePageSplitSection.SplitLeft {
      flex-direction: column-reverse;
    }


  }

  @media (max-width: 780px){
    .WhyWorkWithUsReasons{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
    }

    .WhyWorkWithUsThird{
        max-width: 300px;
    }

    .WhyWorkWithUsBannerBG{
        background-position: 50% 0%;
      }

      .Recruitment .WhyWorkWithUsBanner {
        height: 324px;
      }

      .Recruitment .WhyWorkWithUsBannerBG {
        height: 324px;
      }
  }

  @media (max-width: 600px) {


    .ArchjobsHome .WhyWorkWithUsSectionTitle {
      font-size: 24px;
      line-height: 1.2;
    }

    .ArchjobsHome .WhyWorkWithUsSectionSubtitle {
      font-size: 18px;
      margin-top: 5px;
    }

    .ArchjobsHome .HomePageSectionTitle {
      font-size: 22px;
    }

    .ArchjobsHome .HomePageSectionSubtitle {
      font-size: 16px;
    }

    .ArchjobsHome .WhyWorkWithUsBanner {
      height: 180px;
    }

    .ArchjobsHome .WhyWorkWithUsBannerBG.HomepageBannerBG {
      height: 180px;
    }

  }


  @media (max-width: 500px){
    .WhyWorkWithUsHeaderTitle{
        font-size: 30px;
        line-height: 36px;
    }

    .WhyWorkWithUsHeaderText{
       font-size: 18px;
       line-height: 24px;

    }

    .HomePageSectionTitle {
      font-size: 26px;
    }

    .HomePageSectionSubtitle {
      font-size: 18px;
    }

    .Recruitment .WhyWorkWithUsSectionTitle {
      font-size: 22px;
      padding: 12px 20px;
    }

    .Recruitment .Button {
      padding: 8px 16px;
      font-size: 18px;
  }

    .Recruitment .WhyWorkWithUsSectionSubtitle {
      font-size: 18px;
    }


    .ContactLinks {

      font-size: 16px;
    }
    .RecruitmentPage .ContactLinks {
      margin-left: 0px;

    }

    .PackageDescriptionText{
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding-top: 2px;
      color: #484E57;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      max-width: 300px;
      text-align: left;
    }

    .PostJobButton{
        padding: 8px 16px;
        font-size: 16px;
    }

    .FAQTitle {
      font-size: 26px;
    }

    .WhyWorkWithUsCoachingPackage {
      padding: 20px 10px;
      width: calc(100% - 20px);
    }

    .WhyWorkWithUsSectionTitle {
        font-size: 26px;
        line-height: 30px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .WhyWorkWithUsBannerTextContainer .WhyWorkWithUsSectionTitleBold {
        font-size: 30px;
    }

    #ChoosePackageSection .WhyWorkWithUsSectionTitleBold {
        font-size: 28px;
    }

    .WhyWorkWithUsTestimonialColumns {
        grid-template-columns: 1fr;
    }

    .WhyWorkWithUsSection .MeetTheCoach {
        font-size: 16px;
        line-height: 22px;
        padding-left: 5px;
        padding-right: 5px;
        max-width: calc(100% -10px);
        text-align: center;
    }

    .WhyWorkWithUsSectionTitleBold {
      font-size: 24px;
    }

    .WhyWorkWithUsSectionRetainer {
      font-size: 18px;
      line-height: 25px;
      padding-left: 5px;
        padding-right: 5px;
  }


    .WhyWorkWithUsSectionSubtitle {
        font-size: 18px;
        line-height: 20px;
    }

    .WhyWorkWithUsTestimonials{
      flex-direction: column;
      align-items: center;
      grid-template-columns: 1fr;
      row-gap: 20px;
      padding: 20px;
    }

    .TaylrHomePageFeatures {
      grid-template-columns: 1fr;
      padding-left: 10px;
      padding-right: 10px;

    }

    .FAQContainer{
      display: flex;
    flex-direction: column;
    align-items: center;
    }
    
    .CircularImage {
      width: 180px;
      min-width: 180px;
      height: 180px;
    }

  }

  @media (max-width: 400px){
    /* .Recruitment .Navbar .ArchjobsTitle1 {
      display: none;
    }

    .Recruitment .Navbar .ArchjobsTitle2 {
      display: none;
    } */

    .Recruitment .WhyWorkWithUsSectionTitle {
      border-radius: 10px;
      padding: 8px 16px;
    } 

    .Recruitment .WhyWorkWithUsSectionSubtitle {
      border-radius: 10px;
      margin-top: -10px;
      padding: 8px 16px;
    } 
  }

  .MeetTheCoachContainer {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 0;
  }

  .MeetTheCoachImageContainer {
    flex: 0 0 200px;
  }

  .MeetTheCoachContent {
    flex: 1;
  }

  .CoachAuthorImg {
    width: 100%;
    height: auto;
    border-radius: 50%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border: 3px solid #f6efe8;
  }

  @media (max-width: 768px) {
    .MeetTheCoachContainer {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .MeetTheCoachImageContainer {
      flex: 0 0 auto;
      max-width: 250px;
      margin-bottom: 1.5rem;
    }
  }

  .WhyWorkWithUsSectionTestimonials {
    padding: 2rem;
    max-width: 700px;
    margin: 0 auto;
  }

  .WhyWorkWithUsTestimonial {
    padding: 2rem;
    margin: 1rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: auto;
  }

  .WhyWorkWithUsTestimonialText {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
  }

  .TestimonialName {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-top: 15px;
  }

  /* Customize slick carousel arrows */
  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    z-index: 1;
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next {
    right: -50px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 40px;
    color: #333;
  }

  /* Customize dots */
  .slick-dots {
    bottom: -35px;
  }

  .slick-dots li button:before {
    font-size: 12px;
    color: #333;
  }

  .TaylrHomePageTestimonialQuote {
    font-size: 24px;
    color: #1D3863;
    margin-bottom: 15px;
  }

  /* Mobile Responsive Styles */
  @media (max-width: 768px) {
    .WhyWorkWithUsSectionTestimonials {
      padding: 1rem;
    }

    .WhyWorkWithUsTestimonial {
      padding: 1.5rem;
      margin: 0.5rem;
    }

    .WhyWorkWithUsTestimonialText {
      font-size: 14px;
      line-height: 1.4;
    }

    .TestimonialName {
      font-size: 14px;
      margin-top: 10px;
    }

    .slick-dots {
      bottom: -25px;
    }

    .slick-dots li button:before {
      font-size: 8px;
    }

    .WhyWorkWithUsSectionTitle {
      font-size: 24px;
      padding: 0 20px;
      text-align: center;
    }

    .WhyWorkWithUsSectionSubtitle {
      font-size: 16px;
      padding: 0 20px;
      text-align: center;
      margin-bottom: 20px;
    }

    .TaylrHomePageTestimonialQuote {
      font-size: 20px;
      margin-bottom: 10px;
    }

    /* Hide arrows on mobile */
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }

  /* Small mobile devices */
  @media (max-width: 480px) {
    .WhyWorkWithUsSectionTestimonials {
      padding: 0.5rem;
    }

    .WhyWorkWithUsTestimonial {
      padding: 1rem;
      margin: 0.25rem;
    }

    .WhyWorkWithUsTestimonialText {
      font-size: 13px;
      line-height: 1.3;
    }

    .WhyWorkWithUsSectionTitle {
      font-size: 22px;
    }

    .WhyWorkWithUsSectionSubtitle {
      font-size: 14px;
    }
  }

  /* FAQ Section Styles */
  .FAQContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
  }

  .FAQItem {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    background: #fff;
    margin-bottom: 0;
  }

  .FAQQuestion {
    width: 100%;
    padding: 20px;
    /* background: #fff; */
    font-size: 17px;
    font-weight: 600;
    color: #333;
    border-bottom: 1px solid #eee;
  }

  .FAQAnswer {
    padding: 20px;
    background: #fff;
    font-size: 16px;
    line-height: 1.6;
    color: #666;
  }

  /* Mobile styles */
  @media (max-width: 768px) {
    .FAQContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 20px 15px;
    }

    .FAQItem {
      margin-bottom: 10px;
    }

    .FAQQuestion {
      font-size: 16px;
      padding: 15px;
    }

    .FAQAnswer {
      font-size: 14px;
      padding: 15px;
    }
  }