.Page{
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #FAF9F8;
  align-items: center;
  min-width: 0;
}

.Page.Recruitment {
  background: #FBF8EC;
}

.AdminPage{
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.AdminAuth{
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.AdminPortalText{
  font-size: 18px;
  margin-bottom: 10px;;
}

.JobSubmissionTitle{
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 20px;
}

.JobSubmissionContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1300px;
  justify-content: center;
}

.JobSubmission{
  max-width: 600px;
  padding: 10px;
  font-size: 14px;
  overflow-y: auto;
  max-height: 100vh;
}

.JobSubmissionHeader{
  margin-bottom: 5px;
}

.JobSubmissionRow{
  margin-bottom: 10px;
}

.multiSelectInput{
  min-width: 100%;
}

.JobDescriptionInput{
  min-height: 200px;
  background: #fff;
}

.JobSubmissionContainer .JobDescriptionInput {
  min-width: 600px;
  max-height: 90vh;
  margin-bottom: 20px;
  max-width: 600px;
}

.JobSubmissionAlignedRow{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.SalaryInput{
  margin-right: 20px;
}

.AddLocation{
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  margin-right: 10px;
}

.LocationInput{
  margin-bottom: 5px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ValidateLocationButton{
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.JobSubmissionSubmitRow{
  margin-bottom: 50px;
  min-height: 200px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ErrorMessage{
  margin-top: 10px;
  color: #E96565;
}

.link {
  text-decoration: none;
}

.AlertIcon{
  font-size: 30px;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-left: 0px;
  margin-right: -2px;
  color: #484E57;
}

.HomePage{
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #d9dadc;
  min-height: calc(100vh - 176px);
  align-items: stretch;
  width: 100%;
}

.Recruitment .HomePage {
  background: #faf7f6;
}

.HomePageBg{
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: auto;
  background-position: 50% 52%;
  opacity: 0.8;
}

.HomePageBg{
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: auto;
  background-position: 50% 52%;
  opacity: 0.8;
}

.HomePageSection {
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  /* padding-bottom: 10%; */

}

.RecruitmentHomePageSection {
  /* Frame 70 */
  background: radial-gradient(107.66% 510.14% at 0% 2.24%, #073631 20.49%, #04423A 48.19%, #044E45 81.94%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  color: #F6EFE8;
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding-bottom: 5vh;
}

.RecruitmentHomePageSection .HomePageSectionTitle {
  color: #F6EFE8;
}

.RecruitmentHomePageSection .HomePageSectionSubtitle {
  color: #F6EFE8;
}



.HomePageSplitSection {
  padding-top: 2%;
  display: flex;
  flex-direction: row;
  width: 1000px;
  max-width: calc(100% - 20px);
  justify-content: space-between;
  column-gap: 50px;

}

.ContactPage {
  padding-top: 50px;
}

.ContactPage .HomePageSplitSection  {
  justify-content: center;
  padding-top: 0px;
}

.ContactPage .RecruitmentFirstSection {
  padding-bottom: 4%;
}


.ContactPage .SplitSectionText  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 5px;
}

.PrivacyPolicyText {
  max-width: calc(100% - 20px);
  width: 800px;
  line-height: 1.3;
  font-family: Inter;
}

.Recruitment .PrivacyPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.3;
  font-family: Inter;
}

.PrivacyPageContent {
  width: 800px;
  max-width: calc(100% - 20px);
}

.HomePageSectionSeparatorContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

}
.HomePageSectionSeparator {
  border-bottom: 2px solid #073631;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 1100px;
  max-width: calc(100% - 20px);
}

.HomePageSplitSection.SplitRight {
  flex-direction: row-reverse;
}

.CircularImage {
  width: 260px;
  min-width: 260px;
  height: 260px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #f6efe8;
}

.SectionSeparator {
  font-size: 80px;
  font-weight: 600;
  color: #f6efe8;
}

.HomePageSectionTitle {
  color: #1F2023;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
}

.Recruitment .HomePageSection {
  margin-bottom: 5vh;
}

.Recruitment .HomePageSectionTitle {
  font-family: "Inter";
}

.HomePageSectionSubtitle {
  color: #1F2023;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.3;
  /* text-align: center; */
}

.Recruitment .HomePageSectionSubtitle {
  font-family: "Inter";
}

.SplitSectionText .HomePageSectionSubtitle {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.HomePageLogos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  column-gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.HomePageLogo {
  max-width: 140px;
  height: auto;
  /* border: 1px solid #073631; */
}

.HomePageSectionText{
  width: 1000px;
  max-width: calc(100% - 20px);
  text-align: center;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.RecruitmentFirstSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 4%;
  padding-bottom: 3%;

}

.RecruitmentFirstSection .HomePageSectionText {
  row-gap: 20px;
  margin-bottom: 40px;
}

#ChoosePackageSection .WorkWithMeList.PricingPackages  {
  align-items: flex-start;
  column-gap: 5%;
}

.HomePageWorkWithMeReason {
  display: flex;
  flex-direction: column;
  max-width: 30%;
  font-family: Inter;
  font-size: 22px;
  row-gap: 20px;
  text-align: center;
  align-items: center;
}

.WorkWithMeList {
  margin-top: 40px;
  width: 1100px;
  max-width: calc(100% - 20px);
}

.WorkWithMeNumber {
  border: 3px solid #FDD85E;
  color: #FFF;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #09423C;
  height: 130px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.MyApproachSection {
  margin-top: 5vh;
}

.MyApproachNumber {
  color: #FFF;

  text-align: center;
  font-family: "Josefin Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 3px solid #073631;

  background: #09423C;
  height: 76px;
  min-width: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.MyApproachRow {
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  align-items: center;
  color: #1F2023;

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 1;
}

.MyApproachList {
  margin-bottom: 2%;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 800px;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: calc(100% - 20px)
}

.MyApproachSection .HomePageSectionSubtitle {
  width: 600px;
  max-width: calc(100% - 20px);
}

.RecruitmentPage .MyApproachList {
  width: 600px;
  max-width: calc(100% - 20px);
}

.MyApproachList ol {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 300;
  font-family: Inter;
}

.MyApproachList li {
  margin-bottom: 10px;
  font-family: Inter;
}

.VideoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1100px;
  max-width: 100%;
  margin-top: 5vh;
  row-gap: 20px;
}

.yt-lite {
  width: 800px;
  max-width: 100%;
  /* height: 800px; */
  aspect-ratio: 16 / 9;
}

.MyApproachLine {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 38px;
  border-left: 6px solid #FDD85E;
}

.HomePageContent{
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.HomePageNewsletter .NewsletterPageTitle{
  margin-top: 20px;
  margin-bottom: -5px;
}

.HomePageTitle{
  margin-top: 15vh;
  color: #343639;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  margin-top: 80px;
}

.HomePageSubtitle{
  margin-top: 2vh;
  color: #353535;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  max-width: 700px;
}


.HomePageSearch{
  margin-top: 5vh;
}

.SearchBarContainer{
  display: flex;
  flex-direction: row;
  font-size: 18px;
  justify-content: center;
}

.SearchBar {
  width: 400px;
  margin-left: 5px;
  margin-right: 5px;
}

.SearchIconX {
  display: none;
  color: #E96565;
  cursor: pointer;
  font-size: 20px;
}

.Mui-focused .SearchIconDefault{
  display: none;
}

.Mui-focused .SearchIconX{
  display: inline-block;
}

.SearchBar .MuiFormControl-root .MuiOutlinedInput-root{
  background-color: #fff !important;
  padding: 2px !important;
  /* border: 1px solid #B0B0B0; */
}

#SearchFieldWhatText p{
  color: #484E57;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 2px;
}

#SearchFieldWhereText p {
  color: #484E57;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 2px;
}

.SearchButton{
  margin: 2px;
}

.SearchIcon{
  font-size: 20px;
  margin-right: 5px;
}


.HomePagePopularSearches{
  margin-top: 12vh;
}

.HomePagePopularSearchesTitle{
  font-weight: 600;
  font-size: 32px;
  color: #353535;
  text-align: center;
}

.PopularSearchElement {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
  font-size: 16px;
  color: #353535;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 5px;
  background: #fff;
  cursor: pointer;
}

.HomePagePopularSearchesList{
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 800px;
  align-items: center;
  justify-content: center;
}

.SearchResultHeader{
  min-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SearchResultDateUploaded{
  font-size: 14px; 
  color: #7A7A7A;
}

.SearchResultDateUploaded.Featured{
  color:  #598645;
  font-weight: 500;
  /* text-transform: uppercase; */
  margin-left: -20px;
  margin-top: -12px;
  padding-top: 4px;
  padding-right: 5px;
  margin-bottom: 10px;
  padding-left: 5px;
  background-color: #C5F2B0;
  border: 1px solid #598645;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0px;
}

.SearchHeaderButtons{
  display: flex;

}

.HomePageSummaryCentered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HomePageSummary {
  align-items: center;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  max-width: 900px;
  width: 100%;
  z-index: 1;
}

.HomePageHighlightContainer {
  align-items: center;
  background-color: #fcfbfb;
  border-radius: 10px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 32%;
  min-height: 100%;
  opacity: 1;
  margin-bottom: 40px;
  box-shadow: 0px 2px 2px 1px rgba(128, 136, 148, 0.62);
  border-radius: 4px;
}

.HomePageNewsletterBackground {
  background-color: #f1ebe6;
  ;
  border-radius: 15px;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  width: 100%;
  margin: 10px;
  opacity: 1;
  margin-bottom: 40px;
  box-shadow: 0px 2px 2px 1px rgba(128, 136, 148, 0.62);
  border-radius: 4px;
}

.HighlightTextContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border-radius: 8px;
}

.HighlightTitle {
  color: #343639;
  font-size: 22px;
  font-weight: 600;
  line-height: 120%;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  display: flex;
  text-align: center;
}

.HighlightText {
  color: #343639;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  align-items: center;
  display: flex;
  text-align: center;
}

.Button.SmallButton{
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  color: #484E57;
  background-color: #fff;
  border: 1px solid #DEDEDE;
  margin-left: 5px;
  margin-top: -5px;
  border-radius: 5px;
  margin-bottom: -5px;
}

.SmallButton.Saved{
  background: #FFDDDD;
  border: 0.5px solid #FFDDDD;
  color: #E96565;
}

.SmallHeart{
  font-size: 120%;
  margin-left: 2px;
}

.Button.SmallApplyButton{
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 5px;
  margin-top: -5px;
  border-radius: 5px;
  margin-bottom: -5px;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 50%);
  height: 33px;
}

.Button.SmallButton:hover{
  background-color: #DEDEDE;
}

.SmallButtonIcon{
  margin-left: 5px;
}

.SearchResultBottom{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.ExpandArrow{
  font-size: 24px;
  cursor: pointer;
  font-weight: 600;
  margin-left: 5px;
}

.JobTag {
  font-size: 16px;
  color: #505A6B;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin: 2px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #505A6B;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.JobTag.AJ100{
  color: #766E5A;
  border-color: #766E5A;
  background: #FBF1D7;
}

.JobTag.Urgent{
  color: #E96565;
  border-color: #E96565;
  background: #FFDDDD;
}

.JobTag.Featured{
  color: #44634C;
  border-color: #44634C;
  background: #DAFCE4;
}

.SearchResultTags{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.JobTagIcon{
  margin-right: 4px;
  font-size: 16px;
}

.JobAdHeader{
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid #CFD6DE;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}

.JobAdSummary{
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid #CFD6DE;
}
/* 
.JobAdSummarySegment{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: masonry;
} */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.JobAdHeaderApply{
  margin-top: 5px;
  height: 44px;
  display: flex;
  align-items: stretch;
}

.Button.JobAdHeaderApplyButton {
  font-weight: 600;
  font-size: 18px;
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  min-width: 140px;
}

.JobAdHeaderInfoCompany{
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  color: #18332d;
}

.JobAdHeaderInfoRow{
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  color: #505A6B;
}

.RowDivider{
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 8px;
  background: #D9D9D9;
}

.JobAdHeaderInfoClosing{
  margin-top: 4px;
}

/* .JobAdSummary{
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #505A6B;
} */

.JobAdSummary ul{
  margin-block-start: 4px;
  margin-block-end: 10px;
}

.JobAdSummaryTitle{
  color: #454E58;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.JobAdIcon{
  margin-right: 5px;
  margin-top: -2px;
}

.USPIcon{
  margin-top: -2px;
  margin-right: 5px;
  color: #63A2D8;
}

.JobAdDescriptionHeader{
  color: #353535;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid #CFD6DE;
}


.JobAdApplyCentered{
  display: flex;
  align-items: center;
  justify-content: center;
}

.PostJobFormContainer {
  background: #FFFFFF;
  border: 1px solid #D4D4D4;
  box-shadow: 0px 4px 4px 2px rgba(196, 209, 231, 0.62);
  border-radius: 8px;
  padding: 30px 40px;
  max-width: 820px;
  width: calc(100% - 80px);
}

.PostJobFormPage{
  padding-top: 40px;
  padding-bottom: 40px;
}

.MuiOutlinedInput-notchedOutline legend {
  display: none !important;
}

.MuiOutlinedInput-notchedOutline {
  top: 0px !important;
}

.PostJobPage{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 750px;
}

.PostJobForm{
  display: flex;
  flex-direction: column;
  background: #FCFCFC;
  border: 1px solid #DBD5D5;
  border-radius: 8px;
  padding: 10px 20px;
  max-width: 1200px;
  width: calc(100% - 40px);
}

.PostJobFormHeader{
    font-weight: 500;
    font-size: 20px;
    color: #343639;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .PostJobFormText{
    font-weight: 400;
    font-size: 16px;
    color: #353535;
    margin-bottom: 10px;
  }

  .PostJobFormNotice a{
    color: #484E57;
    text-decoration: underline;
  }

  .ql-toolbar.ql-snow {
    margin-top: 10px;
  }

  .PostJobFormNotice{
    font-weight: 500;
    font-size: 14px;
    color: #484E57;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .PostJobFormDivider{
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 2px solid #D9D9D9;
  }

  .JobSubmissionsubtitle{
    font-size: 14px;
    color: #505A6B;
    margin-top: 3px;
  }

  .PostJobFormInputRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
    column-gap: 5px;
  }

  .PostJobFormInputTitle {
    color: #343639;
    min-width: 150px;
    max-width: 150px;
    position: top;
  }

  .PostJobFormInputRow.TopAligned{
    align-items: start;
  } 

  
  .PostJobInput {
    width: 400px;
    max-width: 100%;
    background: #fff;
  }

  .AdminPage .PostJobInput{
    width: 600px;
  }

  .PostJobInput .MuiOutlinedInput-input{
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .PostJobInput .MuiOutlinedInput-root{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .PostJobInput .MuiInputLabel-root{
    margin-top: -8px !important;
  }

  .JobSubmissionForm .MuiRadio-root{
    padding: 4px !important;
    padding-left: 10px !important;
  }

  .JobSubmissionForm .MuiTypography-root{
    font-size: 12px !important;
  }
  
  .JobDescriptionInput{
    display: flex;
    flex-direction: column;

  }

  .JobDescriptionInputContainer{
    flex: 1 1 auto;
    font-size: 16px;
  
  }
  .PostJobFormSubmit{
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PostJobFormError{
    margin-top: 10px;
    height: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #E96565;
  }

  .SuccessModal .MuiPaper-root{
    background-color: #f9fff7;
    color: #475542;
    display: flex;
    padding: 20px;
    flex-direction: column;
    position: relative;
  }

  .Modal .MuiPaper-root{
    /* background-color: #C4ECB6;
    color: #475542; */
    display: flex;
    padding: 20px;
    flex-direction: column;
    position: relative;
    max-width: 540px;
  }

  .SuccessModalTitle {
    font-weight: 500;
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  .ApplyModalTitle {
    font-weight: 500;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  .SuccessModalText{
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
  }

  .ModalRecruiterContainer {
    margin-bottom: 10px;
  }

  .NewHighlight {
    color: #cf301b;
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    margin-left: -10px;
    margin-right: -14px;
    margin-top: -14px;
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    vertical-align: text-top;
}

  .ModalClose{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 24px;
  }


  .DefaultModal .MuiPaper-root{
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
    width: 400px;
  }

  .DefaultModalContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  }

  .DefaultModalTitle {
    font-weight: 500;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  .ModalButtons{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .ModalButton{
    min-width: 100px;
    justify-content: center !important;
  }

  .ModalButton svg{
    margin-right: 5px;
  }

  .DefaultModalSubTitle{
    margin-bottom: 5px;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  #fileUpload {
    display: none;
}

.FileUploadButton{
  width: 120px;
}

.CancelUpload{
  color: #E96565;
  cursor: pointer;
}

.uploadedFile{
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.MultipleUpload{
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}

.MultipleUpload label {
  display: inline-block;
  max-width: 140px;
}

.FileInputContainer{
  margin-bottom: 10px;
}

.ApplyReviewMessageContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

}

.RequiredNote{
  color: #ec6161;
  font-weight: 500;

}

.ApplicationNoteTitle{
  font-weight: 600;
}

.additionalNote{
  font-weight: 400;
  font-size: 15px;
}

.ApplicationNotes{
  margin-top: 5px;
  font-size: 15px;
  font-weight: 300;
}

.ApplyReviewMessage{
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-top: 40px;
}

.ApplyReviewTagsMessage{
  align-items: center;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.ApplyReviewTags{
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.ApplyJobUrl{
  margin-left: 5px;
  color: #2D63BA;
  text-decoration: underline;
  font-size: 14px;
}

.ApplyModalButtons{
  display: flex;
  flex: row;
  justify-content: center;
  align-items: center;
}

.ApplyModalMessage{
  margin-top: 10px;
  text-align: center;
}

.SearchPageSearch{
  max-width: 100%;
}

.CreateNewsletterPage{
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
}

.NewsletterPopUp .MuiPaper-root{
  width: 550px;
  max-width: 100%;
}

@media (max-width: 930px){
  .SearchBarContainer{
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }

  .SearchBarContainer .SearchBar {
    margin-bottom: 8px;
  }
  
}



.TeamPage{
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
}

.TeamContainer{
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  background: #FCFCFC;
  border: 1px solid #D8DADE;
  border-radius: 5px;
}



.TeamTitle {
  font-weight: 500;
  font-size: 26px;
  color: #343639;
  padding-left: 10px;
  margin-bottom: 10px;
}

.TeamInfoContainer{
  display: flex;
  flex-direction: row;
}

.TeamInfo{
  margin-left: 10px;
  
}

.TeamPhoto{
  padding: 10px;
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
}

.TeamInfoSection{
  padding-bottom: 10px;
  padding-top: 10px;
}
.TeamName{
  font-weight: 600;
  color: #343639;
}

.TeamJob{
  font-weight: 500;
  color: #484E57;
}

.TeamDetails{
  color: #484E57; 
}

.TeamPhoto.Otto {
  margin-top: 30px;
}

.TeamPhotos{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.TeamPhoto {
  width: 200px;
}

.NewsletterPage{
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #FCFCFC;
  min-height: calc(100vh - 176px);
  align-items: stretch;
  width: 100%;
}

.NewsletterPageBg{
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-image: url("https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/archjobs_newsletter_bg.jpg"); 
  background-size: auto;
  background-position: 50% 50%;
  opacity: 0.15;
}


.NewsletterPageTitle {
  margin-top: 15vh;
  color: #353535;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  max-width: 900px;
}

.PopUpContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  row-gap: 10px;
  padding-top: 30px;
}

.PopUpContent .NewsletterSignup{
  flex-direction: column;
}

.NewsletterEmailContainer{
  width: 100%;
  margin-top: 10px;
}

.PopUpTitle {
  color: #353535;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  max-width: 800px;
  margin-top: 10px;
}

.PopUpText {
  color: #353535;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  max-width: 800px;
  margin-bottom: 10px;
}

.PopUpText p {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 10px;
}

.NewsletterPageSubtitle {
  margin-top: 2vh;
  color: #353535;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  max-width: 800px;
}

.JobAlertInputWide .MuiOutlinedInput-input {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
}

.mailImage {
  font-size: 20px;
}

.JobAlertInputWide {
  background: #fff;
  width: 400px;
  max-width: 80%;
}
.NewsletterSignupContainer{
  margin-top: -5px;
  margin-bottom: -5px;

}

.NewsletterSignupButton {
  height: 38px;
  display: flex;
  align-items: stretch;
  min-width: 100px;

}

.NewsletterSignupButton .SearchButton{
  width: 100%;
  font-size: 18px;
}

.NewsletterSignup{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.HomePageNewsletterBackground{
  margin-top: 30px;
  padding: 30px;
}

 ul {
  list-style-type: circle;
 }

 .FooterSubtext {
  margin-top: 10px;
 }

 .Recruitment .Footer {
  background: radial-gradient(107.66% 510.14% at 0% 2.24%, #073631 20.49%, #04423A 48.19%, #044E45 81.94%);

  color: #f1f4f4;
 }

 .Recruitment .Footer .NavbarTitle {
  margin-top: 10px;
  font-size: 36px;
  justify-content: center;
  background: none;
 }
 

 .Recruitment .FooterContainer {
  padding: 20px 40px;
 }

  .Recruitment .FooterSubtext a {
    color: #a5a5a5;
  }

  .Recruitment .FooterContent {
    justify-content: center;
  }

@media (max-width: 500px) {
  .TeamPhoto {
    height: 290px;
    object-fit: cover;
  }

  .TeamInfoContainer{
    flex-direction: column;
  }

  .TeamPhotos{
    flex-direction: row;
  }

  .TeamPhoto.Otto {
    margin-top: 0px;
  }

}

@media (max-width: 420px) {
  .TeamPhoto {
    width: 150px;
    height: 250px;
  }
}
@media (max-width: 380px) {
  .TeamPhoto {
    width: 120px;
    height: 180px;
  }
}

.FAQContainer{
  max-width: 1060px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  padding-top: 20px;
  background: #FCFCFC;
  border: 1px solid #D8DADE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: #343639;
  min-height: 200px;
  position: relative;
  z-index: 3;
}

.FAQContent{
  z-index: 5;
}

.FAQBackground{
  background-image: url("https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/FAQ_background_1100.jpg");
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
  
  background-size: auto;
  background-position: 50% 0%;
  opacity: 0.3;
}

.FAQTitle{
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 30px;
}

.FAQSubTitle{
  text-align: center;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 18px;
}

.FAQContact{
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
}

.FAQContact a{
  color: #2D63BA;
}

.FAQQuestion {
  font-weight: 500;
  font-size: 18px;
  color: #343639;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.PlusIcon{
  margin-bottom: 1px;
}

.FAQAnswer{
  font-size: 15px;
}

.FAQSectionContainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
}

.skeleton {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background-color: #dddddd !important;
  color: transparent !important;
  border-color: #dddddd  !important;
  user-select: none;
  cursor: default;
 }
 
 .skeleton img {
  opacity: 0;
 }
 
 .skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 30%,
    rgba(255, 255, 255, 0.3) 70%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
  content: '';
 }

 a {
  color: #4d6e99;
 }

 .SmallText {
  padding-top: 1px;
  padding-bottom: 2px;
  height: 1em;
  width: 100px;
  max-width: 100%;
 }

 .MediumText {
  padding-top: 1px;
  padding-bottom: 2px;
  height: 1em;
  width: 200px;
  max-width: 100%;
 }

 .LongText {
  padding-top: 1px;
  padding-bottom: 2px;
  height: 1em;
  width: 400px;
  max-width: 100%;
  flex-shrink: 1;
 }

 .FullWidthText {
  margin-top: 1px;
  margin-bottom: 4px;
  height: 1em;
  min-width: 100%;
  max-width: 100%;
 }

.SearchResultImg.skeleton {
  flex-shrink: 0 !important
}

 .SkeletonTag{
  min-height: 19px;
  min-width: 80px;
  border-radius: 12px !important;
 }

 @media (max-width: 650px) {
  .HomePageSummary{
    flex-direction: column;
    align-items: center;
    margin-top: -10px;
  }

  .HomePageHighlightContainer {
    width: 90%;
    max-width: 400px;
    margin-bottom: 15px;
  }

  .HomePageNewsletterBackground{
    width: 90%;
    max-width: 400px;
    padding: 30px;
  }

  .WorkWithMeNumber {
    height:80px;
    width: 80px;
    font-size: 34px;
  }

  .WorkWithMeText {
    font-size: 16px;
  }

  .Recruitment .PricingPackages {
    flex-wrap: wrap;
  }

  .HomePageWorkWithMeReason {
    min-width: 200px;
  }

  .MyApproachText{
    font-size: 16px;
  }

  .MyApproachLine {
    left: 28px;
  }

  .MyApproachRow {
    column-gap: 20px;
  }

  .MyApproachNumber {
    height: 56px;
    width: 56px;
    font-size: 24px;
    min-width: 56px;
  }

  .HomePageSectionText {
    padding-top: 40px;
  }
 }  


 
 @keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
 }

 @media (max-width: 430px){


  .SearchBarContainer .SearchBar {
    margin-bottom: 8px;
    max-width: 90%;
  }
  
}

@media (max-width: 600px){
  .Recruitment .Footer .NavbarTitle {
    margin-top: 5px;
    font-size: 22px;
    text-align: center;
   }

  .HomePageTitle{
    font-size: 28px;
    margin-top: 40px;
  }

  .HomePageSubtitle{
    font-size: 18px;
    max-width: 400px;
    margin-bottom: -20px;
  }

  .HomePagePopularSearches{
    margin-top: 30px;;
  }

  .HomePagePopularSearchesTitle{
    font-size: 24px;
  }

  .HomePageNewsletter .NewsletterPageTitle{
    font-size: 24px;
  }

  .PopularSearchElement{
    font-size: 16px;
  }

  .HomePageSearch{
    max-width: 100%;
   
  }

  .HighlightTitle {
    color: #343639;
    font-size: 24px;
  }

  .HighlightText{
    font-size: 18px;
  }

  .SearchBarContainer .MuiOutlinedInput-root {
    font-size: 80%;
  }

  .SearchBarContainer #SearchFieldWhatText p{
    font-size: 90% !important;
  }
  
  .SearchBarContainer #SearchFieldWhereText p{
    font-size: 90% !important;
  }

  .SearchIcon{
    font-size: 16px;
  }

  .SearchIcon{
    font-size: 16px;
  }

  .HomePageContent{
    max-width: 94%;
    padding: 3%;
  }

  .SearchResultDateUploaded.Featured{
    color:  #598645;
    font-weight: 500;
    /* text-transform: uppercase; */
    margin-left: -10px;
    margin-top: -6px;
    padding-top: 4px;
    padding-right: 5px;
    margin-bottom: 4px;
    padding-left: 5px;
  }
}

@media (max-width: 700px){
  .PostJobFormInputRow {
    flex-direction: column;
    align-items: flex-start;
  }

  .PostJobFormContainer{
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
    width: calc(100% - 20px);
  }

  .PostJobFormInputTitle{
    margin-bottom: 5px;
    max-width: 100%;
    width: auto;
  }

  .JobSubmissionsubtitle{
    max-width: 100%;
  }

  .JobDescriptionInputContainer{
    width: 100%;
  }
}

@media (max-width: 500px) {
  .PostJobInput{
    width: 100%;
  }


}

/* Coaching Payment Styles */
.CoachingPackages {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.PackageCard {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  text-align: center;
}

.PackageCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.PackageCard h2 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.PackageCard .Price {
  font-size: 2rem;
  font-weight: bold;
  color: #2E7D32;
  margin: 1rem 0;
}

.PackageCard p {
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.4;
}

.PaymentPopup .MuiDialog-paper {
  border-radius: 12px;
  overflow: hidden;
}

.PaymentPopup .PopUpContent {
  padding: 1rem;
}

.PaymentPopup .SearchButton {
  min-width: 200px;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .CoachingPackages {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  
  .PackageCard {
    padding: 1.5rem;
  }
}